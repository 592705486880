@import './colors';

.ProjectDescription-upper {
  div {
    margin: 0.2rem 0;
  }
  padding: 0.5rem 3rem 0.2rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto; // Grow, don't shrink
  @media (max-width: 767.99px) {
    padding: 0.5rem 1.5rem 0.2rem 1.5rem;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .description {
    padding-right: 2rem;
    flex: 1;
    @media (max-width: 767.99px) {
      flex: 0;
    }
  }
  .links {
    font-size: 0.9rem;
    text-align: center;
    list-style-type: none;
    a {
      &:hover {
        border-bottom: 2px dotted $gold;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: right;
      @media (max-width: 767.99px) {
        li {
          display: inline;
          &:not(:first-child):before {
            content: ' - ';
          }
        }
      }
    }
  }
}
.ProjectDescription-lower {
  padding: 0.2rem 3rem 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto; // Grow, don't shrink
  div {
    margin: 0.2rem 0;
  }
  @media (max-width: 767.99px) {
    padding: 0.2rem 1.5rem 0.5rem 1.5rem;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
  .tags {
    font-size: 0.9rem;
    padding-right: 2rem;
    flex: 1;
    color: $light;
    @media (max-width: 767.99px) {
      flex: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    a {
      padding: 1rem;
      margin: 0.1rem 0.1rem;
      border: 1px dashed $gold;
      @media (max-width: 1199.99px) {
        padding: 0.5rem;
      }
      &.mainLink {
        @media (max-width: 767.99px) {
          .longTitle {
            display: none;
          }
          .shortTitle {
            display: inline !important;
          }
        }
      }
      &:hover {
        color: $dark;
        background-color: $gold;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
}
