@import './colors';

.ProjectTitle {
  position: relative;
  height: 45vh;
  overflow: hidden;
  @media (max-width: 1199.99px) {
    height: 30vh;
  }
  @media (max-width: 767.99px) {
    height: 25vh;
  }
  .titleBg {
    position: absolute;
    top: -5px;
    left: -5px;
    height: calc(100% + 5px + 5px);
    width: calc(100% + 5px + 5px);
    background-size: cover;
    background-position: center;
    z-index: 0;
    filter: blur(3px);
    @media (max-width: 767.99px) {
      filter: blur(1.5px);
    }
    opacity: 0;
    transition: opacity 0.5s ease;
    &.visible {
      opacity: 1;
    }
  }
  .titleContainer {
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light;
    a {
      color: $light;
      &:hover {
        border: 0;
      }
    }
    .chevronContainer {
      z-index: 2;
      padding: 0;
      margin: 0 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: $dark;
      display: flex;
      justify-content: center;
      align-items: center;
      &.hidden {
        opacity: 0;
      }
      @media (max-width: 767.99px) {
        margin: 0 0.2rem;
        width: 1.5rem !important;
        height: 1.5rem !important;
      }
      i {
        font-size: 2rem;
        @media (max-width: 767.99px) {
          font-size: 1.3rem;
        }
      }
    }

    h2 {
      z-index: 2;
      font-size: 5rem;
      font-weight: 700;
      text-align: center;
      background-color: #323f4888;
      padding: 1rem;
      max-width: 80%;
      @media (max-width: 1199.99px) {
        font-size: 2.5rem;
      }
      @media (max-width: 767.99px) {
        padding: 0.5rem;
        font-size: 1.7rem;
      }
    }
  }
}
