.AccordionElt {
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  box-shadow: inset 0 -6px 8px rgba(0, 0, 0, 0.514);
  &.visible {
    opacity: 1;
  }
  .wrapper {
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.466);
    }
    h2 {
      margin: 0;
      padding: 1.5rem;
      color: white;
      @media (max-width: 767.99px) {
        padding: 0.8rem;
        font-size: 1.1rem;
      }
      span {
        padding: 0.5rem;
        background-color: #323f48b7;
        @media (max-width: 767.99px) {
          padding: 0.3rem;
        }
      }
    }
  }
}
