@import './colors';

.Header {
  background-color: $dark;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: $gold;
  h1 {
    margin: 0;
    padding-bottom: 0.2rem;
    font-size: 3rem;
    opacity: 0;
    transition: opacity 0.4s ease 0.4s;
    &.show {
      opacity: 1;
    }
    @media (max-width: 767.99px) {
      font-size: 2rem;
    }
  }
  h3 {
    color: $light;
    margin: 1rem 0;
    padding: 0 0.3rem;
    opacity: 0;
    transition: opacity 0.4s ease 1s;
    &.show {
      opacity: 1;
    }
    @media (max-width: 1199.99px) {
      span {
        display: block;
      }
    }
    @media (max-width: 767.99px) {
      margin: 0.2rem 0;
      font-size: 1rem;
    }
  }
}
