@import './colors';

.Tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  color: $dark;
  .separator {
    margin-top: 0.2rem;
    margin-bottom: 2rem;
    opacity: 0;
    transition: opacity 0.5s ease;
    &.show {
      opacity: 1;
    }
  }

  text-align: center;
  .container {
    padding-bottom: 1rem;
    .tag {
      font-family: 'Source Sans Pro', sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      display: inline-block;
      padding: 0.5rem 0.8rem;
      margin: 0;
      opacity: 0;
      transition: opacity 0.5s ease;
      &.show {
        opacity: 1;
      }
      &.active {
        border: 1px solid $green;
      }
    }
  }
  a {
    color: $green;
    &:hover {
      border-bottom: 2px dotted $green;
    }
  }
}
