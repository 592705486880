@import './colors';

.About {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: $dark;
  a {
    color: $green;
    &:hover {
      border-bottom: 2px dotted $green;
    }
  }
  section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 767.99px) {
      flex-direction: column;
    }
    &.upper {
      .description {
        padding: 2rem;
        width: 60%;
        text-align: justify;
        .signature {
          text-align: right;
        }
        @media (max-width: 767.99px) {
          width: auto;
          padding-top: 0;
        }
      }
      .photo {
        padding: 2rem;
        text-align: center;
        @media (max-width: 767.99px) {
          // margin-top: 3rem;
          padding-top: 3.5rem;
          padding-bottom: 1rem;
        }
        img {
          @media (max-width: 767.99px) {
            max-width: 90%;
          }
          width: 20rem;
          border-radius: 50%;
        }
      }
      flex: 1;
    }
    &.lower {
      background-color: $dark;
      color: $light;
      flex: 1;
      padding: 0.5rem 2rem 3rem 2rem;
      a {
        color: $gold;
        &:hover {
          border-bottom: 2px dotted $gold;
        }
      }
      div {
        margin: 0.5rem 0.5rem;
      }
      .github {
        font-size: 0.9rem;
        @media (max-width: 767.99px) {
          padding: 0.5rem 0.8rem 2rem 0.8rem;
        }
        h4 {
          text-align: center;
          font-weight: 700;
          color: $light;
        }
        span {
          margin-left: 0.5rem;
          color: $light;
          i {
            padding: 0 0.4rem;
          }
        }
      }

      .mail {
        text-align: center;
        @media (max-width: 767.99px) {
          // padding: 0.5rem;
        }
        h3 {
          a {
            font-weight: 700;
            padding: 1rem;
            border: 1px dashed $gold;
            &:hover {
              background: $gold;
              color: $dark;
            }
          }
          @media (max-width: 767.99px) {
            margin-bottom: 1rem;
            font-size: 1rem;
          }
        }
      }

      .socials {
        text-align: center;
      }
    }
  }
}
