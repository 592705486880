@import './colors';

.GallerySlide {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: $light;
    &:hover {
      border: 0;
    }
  }
  i {
    text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.425);
    padding: 0 0.2rem;
    font-size: 2rem;
    @media (max-width: 767.99px) {
      font-size: 1.3rem;
    }
    &.hidden {
      opacity: 0;
    }
  }
  img {
    max-height: 80vh;
    max-width: 85%;
  }
}
