@import './colors';

.VideoOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease, z-index 0s ease 0.5s;
  touch-action: none;
  &.visible {
    opacity: 1;
    z-index: 1;
    transition: opacity 0.5s ease;
  }
  .closeOverlay {
    position: absolute;
    background-color: $light;
    padding: 0.5rem;
    top: 0.5rem;
    right: 0.5rem;
    color: $dark;
    cursor: pointer;
  }
  iframe {
    height: 80vh;
    width: calc(80vh * (16 / 9));
    @media (max-aspect-ratio: 16/9) {
      width: 95vw;
      height: calc(95vw * (9 / 16));
    }
  }
}
