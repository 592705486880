@import './colors';

.GalleryOverlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s ease, z-index 0s ease 0.3s;
  touch-action: none;
  &.visible {
    opacity: 1;
    z-index: 1;
    transition: opacity 0.5s ease;
  }
  .closeOverlay {
    position: absolute;
    background-color: $light;
    padding: 0.5rem;
    top: 0.5rem;
    right: 0.5rem;
    color: $dark;
    cursor: pointer;
  }
  .eachSlide {
    background: rgba(0, 0, 0, 0.5);
    color: $light;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
