@import './colors';

.Project {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  // min-height: calc(var(--vh, 1vh) * 100);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  background-color: $dark;
}
