@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Source+Sans+Pro&display=swap');
@import './style/colors';

html {
  scroll-behavior: smooth;
}

body {
  background: $light;
  margin: 0;
  a {
    text-decoration: none;
    transition: all 0.2s ease;
  }
}

.App {
  font-family: 'Source Sans Pro', sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Libre Baskerville', serif;
  }
  h6 {
    font-weight: 300;
  }
}
