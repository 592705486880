@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Source+Sans+Pro&display=swap);
.Header{background-color:#323f48;height:85vh;display:flex;flex-direction:column;justify-content:center;text-align:center;color:#fac07e}.Header h1{margin:0;padding-bottom:.2rem;font-size:3rem;opacity:0;transition:opacity .4s ease .4s}.Header h1.show{opacity:1}@media(max-width: 767.99px){.Header h1{font-size:2rem}}.Header h3{color:#f8f3ea;margin:1rem 0;padding:0 .3rem;opacity:0;transition:opacity .4s ease 1s}.Header h3.show{opacity:1}@media(max-width: 1199.99px){.Header h3 span{display:block}}@media(max-width: 767.99px){.Header h3{margin:.2rem 0;font-size:1rem}}
.Tags{display:flex;flex-direction:column;justify-content:center;padding:2rem 1rem;color:#323f48;text-align:center}.Tags .separator{margin-top:.2rem;margin-bottom:2rem;opacity:0;transition:opacity .5s ease}.Tags .separator.show{opacity:1}.Tags .container{padding-bottom:1rem}.Tags .container .tag{font-family:"Source Sans Pro",sans-serif;text-transform:uppercase;letter-spacing:.1rem;display:inline-block;padding:.5rem .8rem;margin:0;opacity:0;transition:opacity .5s ease}.Tags .container .tag.show{opacity:1}.Tags .container .tag.active{border:1px solid #016450}.Tags a{color:#016450}.Tags a:hover{border-bottom:2px dotted #016450}
.ProjectTitle{position:relative;height:45vh;overflow:hidden}@media(max-width: 1199.99px){.ProjectTitle{height:30vh}}@media(max-width: 767.99px){.ProjectTitle{height:25vh}}.ProjectTitle .titleBg{position:absolute;top:-5px;left:-5px;height:calc(100% + 5px + 5px);width:calc(100% + 5px + 5px);background-size:cover;background-position:center;z-index:0;-webkit-filter:blur(3px);filter:blur(3px);opacity:0;transition:opacity .5s ease}@media(max-width: 767.99px){.ProjectTitle .titleBg{-webkit-filter:blur(1.5px);filter:blur(1.5px)}}.ProjectTitle .titleBg.visible{opacity:1}.ProjectTitle .titleContainer{height:100%;display:flex;justify-content:space-between;align-items:center;color:#f8f3ea}.ProjectTitle .titleContainer a{color:#f8f3ea}.ProjectTitle .titleContainer a:hover{border:0}.ProjectTitle .titleContainer .chevronContainer{z-index:2;padding:0;margin:0 .5rem;width:2.5rem;height:2.5rem;border-radius:50%;background-color:#323f48;display:flex;justify-content:center;align-items:center}.ProjectTitle .titleContainer .chevronContainer.hidden{opacity:0}@media(max-width: 767.99px){.ProjectTitle .titleContainer .chevronContainer{margin:0 .2rem;width:1.5rem !important;height:1.5rem !important}}.ProjectTitle .titleContainer .chevronContainer i{font-size:2rem}@media(max-width: 767.99px){.ProjectTitle .titleContainer .chevronContainer i{font-size:1.3rem}}.ProjectTitle .titleContainer h2{z-index:2;font-size:5rem;font-weight:700;text-align:center;background-color:rgba(50,63,72,.5333333333);padding:1rem;max-width:80%}@media(max-width: 1199.99px){.ProjectTitle .titleContainer h2{font-size:2.5rem}}@media(max-width: 767.99px){.ProjectTitle .titleContainer h2{padding:.5rem;font-size:1.7rem}}
.ProjectDescription-upper{padding:.5rem 3rem .2rem 3rem;display:flex;justify-content:space-between;align-items:center;flex:1 0 auto}.ProjectDescription-upper div{margin:.2rem 0}@media(max-width: 767.99px){.ProjectDescription-upper{padding:.5rem 1.5rem .2rem 1.5rem;justify-content:space-evenly;flex-direction:column}}.ProjectDescription-upper .description{padding-right:2rem;flex:1 1}@media(max-width: 767.99px){.ProjectDescription-upper .description{flex:0 1}}.ProjectDescription-upper .links{font-size:.9rem;text-align:center;list-style-type:none}.ProjectDescription-upper .links a:hover{border-bottom:2px dotted #fac07e}.ProjectDescription-upper .links ul{margin:0;padding:0;list-style-type:none;text-align:right}@media(max-width: 767.99px){.ProjectDescription-upper .links ul li{display:inline}.ProjectDescription-upper .links ul li:not(:first-child):before{content:" - "}}.ProjectDescription-lower{padding:.2rem 3rem .5rem 3rem;display:flex;justify-content:space-between;align-items:center;flex:1 0 auto}.ProjectDescription-lower div{margin:.2rem 0}@media(max-width: 767.99px){.ProjectDescription-lower{padding:.2rem 1.5rem .5rem 1.5rem;justify-content:space-evenly;flex-direction:column-reverse}}.ProjectDescription-lower .tags{font-size:.9rem;padding-right:2rem;flex:1 1;color:#f8f3ea}@media(max-width: 767.99px){.ProjectDescription-lower .tags{flex:0 1}}.ProjectDescription-lower .buttons{display:flex;justify-content:space-evenly;flex-wrap:wrap}.ProjectDescription-lower .buttons a{padding:1rem;margin:.1rem .1rem;border:1px dashed #fac07e}@media(max-width: 1199.99px){.ProjectDescription-lower .buttons a{padding:.5rem}}@media(max-width: 767.99px){.ProjectDescription-lower .buttons a.mainLink .longTitle{display:none}.ProjectDescription-lower .buttons a.mainLink .shortTitle{display:inline !important}}.ProjectDescription-lower .buttons a:hover{color:#323f48;background-color:#fac07e}.ProjectDescription-lower .buttons a i{font-size:1.5rem}
.ProjectImages{display:flex;flex-wrap:nowrap}.ProjectImages span{cursor:pointer;box-shadow:2px -3px 8px rgba(0,0,0,.308);height:20vh;min-width:calc(16.66667% - 0.2rem - 0.2rem);margin:0 .2rem;border-radius:.5rem .5rem 0 0;background-size:cover;background-position:center;opacity:0;transition:opacity .5s ease}@media(max-width: 767.99px){.ProjectImages span{margin:0 .1rem;min-width:calc(25% - 0.1rem - 0.1rem)}}@media(max-width: 576.99px){.ProjectImages span{min-width:calc(33.3% - 0.1rem - 0.1rem)}}.ProjectImages span.visible{opacity:1}
.Project{min-height:100vh;transition:all .3s ease;display:flex;flex-direction:column;justify-content:space-between;width:100%;overflow:hidden;background-color:#323f48}
.Projects{background-color:rgba(0,0,0,.171);box-shadow:0px 3px 10px rgba(0,0,0,.171);z-index:2;color:#f8f3ea}.Projects a{color:#fac07e}
.About{min-height:100vh;display:flex;flex-direction:column;justify-content:space-evenly;color:#323f48}.About a{color:#016450}.About a:hover{border-bottom:2px dotted #016450}.About section{display:flex;justify-content:space-evenly;align-items:center}@media(max-width: 767.99px){.About section{flex-direction:column}}.About section.upper{flex:1 1}.About section.upper .description{padding:2rem;width:60%;text-align:justify}.About section.upper .description .signature{text-align:right}@media(max-width: 767.99px){.About section.upper .description{width:auto;padding-top:0}}.About section.upper .photo{padding:2rem;text-align:center}@media(max-width: 767.99px){.About section.upper .photo{padding-top:3.5rem;padding-bottom:1rem}}.About section.upper .photo img{width:20rem;border-radius:50%}@media(max-width: 767.99px){.About section.upper .photo img{max-width:90%}}.About section.lower{background-color:#323f48;color:#f8f3ea;flex:1 1;padding:.5rem 2rem 3rem 2rem}.About section.lower a{color:#fac07e}.About section.lower a:hover{border-bottom:2px dotted #fac07e}.About section.lower div{margin:.5rem .5rem}.About section.lower .github{font-size:.9rem}@media(max-width: 767.99px){.About section.lower .github{padding:.5rem .8rem 2rem .8rem}}.About section.lower .github h4{text-align:center;font-weight:700;color:#f8f3ea}.About section.lower .github span{margin-left:.5rem;color:#f8f3ea}.About section.lower .github span i{padding:0 .4rem}.About section.lower .mail{text-align:center}.About section.lower .mail h3 a{font-weight:700;padding:1rem;border:1px dashed #fac07e}.About section.lower .mail h3 a:hover{background:#fac07e;color:#323f48}@media(max-width: 767.99px){.About section.lower .mail h3{margin-bottom:1rem;font-size:1rem}}.About section.lower .socials{text-align:center}
.VideoOverlay{background:rgba(0,0,0,.5);position:fixed;height:100vh;width:100%;bottom:0;left:0;z-index:-1;display:flex;justify-content:center;align-items:center;opacity:0;transition:opacity .5s ease,z-index 0s ease .5s;touch-action:none}.VideoOverlay.visible{opacity:1;z-index:1;transition:opacity .5s ease}.VideoOverlay .closeOverlay{position:absolute;background-color:#f8f3ea;padding:.5rem;top:.5rem;right:.5rem;color:#323f48;cursor:pointer}.VideoOverlay iframe{height:80vh;width:142.2222222222vh}@media(max-aspect-ratio: 16/9){.VideoOverlay iframe{width:95vw;height:53.4375vw}}
.AccordionElt{background-size:cover;background-position:center;opacity:0;transition:opacity .5s ease;box-shadow:inset 0 -6px 8px rgba(0,0,0,.514)}.AccordionElt.visible{opacity:1}.AccordionElt .wrapper{cursor:pointer}.AccordionElt .wrapper:hover{background-color:rgba(255,255,255,.466)}.AccordionElt .wrapper h2{margin:0;padding:1.5rem;color:#fff}@media(max-width: 767.99px){.AccordionElt .wrapper h2{padding:.8rem;font-size:1.1rem}}.AccordionElt .wrapper h2 span{padding:.5rem;background-color:rgba(50,63,72,.7176470588)}@media(max-width: 767.99px){.AccordionElt .wrapper h2 span{padding:.3rem}}
.Accordion{width:100%;box-shadow:0px -3px 10px rgba(0,0,0,.171)}
.GallerySlide{height:100%;width:100%;display:flex;justify-content:space-between;align-items:center}.GallerySlide a{color:#f8f3ea}.GallerySlide a:hover{border:0}.GallerySlide i{text-shadow:1px 2px 6px rgba(0,0,0,.425);padding:0 .2rem;font-size:2rem}@media(max-width: 767.99px){.GallerySlide i{font-size:1.3rem}}.GallerySlide i.hidden{opacity:0}.GallerySlide img{max-height:80vh;max-width:85%}
.GalleryOverlay{position:fixed;height:100vh;width:100%;bottom:0;left:0;z-index:-1;opacity:0;transition:opacity .4s ease,z-index 0s ease .3s;touch-action:none}.GalleryOverlay.visible{opacity:1;z-index:1;transition:opacity .5s ease}.GalleryOverlay .closeOverlay{position:absolute;background-color:#f8f3ea;padding:.5rem;top:.5rem;right:.5rem;color:#323f48;cursor:pointer}.GalleryOverlay .eachSlide{background:rgba(0,0,0,.5);color:#f8f3ea;height:100vh;width:100%;display:flex;align-items:center}
html{scroll-behavior:smooth}body{background:#f8f3ea;margin:0}body a{text-decoration:none;transition:all .2s ease}.App{font-family:"Source Sans Pro",sans-serif}.App h1,.App h2,.App h3,.App h4,.App h5,.App h6{font-family:"Libre Baskerville",serif}.App h6{font-weight:300}
