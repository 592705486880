.ProjectImages {
  display: flex;
  flex-wrap: nowrap;
  span {
    cursor: pointer;
    box-shadow: 2px -3px 8px rgba(0, 0, 0, 0.308);
    height: 20vh;
    $between: 0.2rem;
    min-width: calc(16.66667% - #{$between} - #{$between});
    margin: 0 $between;
    border-radius: 0.5rem 0.5rem 0 0;
    background-size: cover;
    background-position: center;
    @media (max-width: 767.99px) {
      $between: 0.1rem;
      margin: 0 $between;
      min-width: calc(25% - #{$between} - #{$between});
    }
    @media (max-width: 576.99px) {
      min-width: calc(33.3% - #{$between} - #{$between});
    }
    opacity: 0;
    transition: opacity 0.5s ease;
    &.visible {
      opacity: 1;
    }
  }
}
